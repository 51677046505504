import React from "react";
import logo from '../assets/logos/yellow-white.png';

function Footer() {
    return (
        <div className='relative bg-primary-dark text-primary flex items-center justify-center text-lg md:text-xl w-full'>
        <div className='flex items-center justify-between w-full pl-6 pr-6 md:w-3/4'>
            <div className='flex flex-col pt-12 pb-12 text-center'>
                <a href="385.400.4719" className="hover:underline pb-4">385.400.4719</a>
                <a target="_blank" rel="noopener noreferrer" href="https://www.google.com/maps/place/2797+S+1900+W,+West+Haven,+UT+84401" className="hover:underline">
                    2797 S 1900 W <br /> Ogden, UT <br /> 84401
                </a>
            </div>
            <div className='pt-12 pb-12 text-center'>
                <p className="pb-4">Hours of Operation:</p>
                <p className="pb-2">M-F 9am-5pm</p>
                <p className="pb-2">Sat 8am-12pm</p>
                <p className="pb-2">Closed Sunday</p>
            </div>
            <div className="hidden md:inline">
                <img src={logo} alt="Greystone Logo" className="h-44" />
            </div>
        </div>
    </div>
    );
}

export default Footer;