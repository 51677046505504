import React, { useEffect, useRef, useState } from 'react';
import '../styles/NavBar.css';
import logo from '../assets/logos/black-logo.png';
import { FaBars, FaTimes } from 'react-icons/fa';
import { Link } from '@tanstack/react-router';

function NavBar() {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  const handleScroll = () => {
    setIsOpen(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  const handleTouchOutside = (event: TouchEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleTouchOutside);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleTouchOutside);
    };
  }, []);

  const toggleMenu = () => {
      setIsOpen(!isOpen);
  };

  return (
    <div>
      <nav className="fixed top-0 left-0 w-full z-50 h-20 bg-primary navbar flex items-center justify-between px-4">
          <div className='md:hidden'>
            <button onClick={toggleMenu} className='text-3xl text-primary-dark'>
                {isOpen ? <FaTimes /> : <FaBars />}
            </button>
          </div>
          <Link to="/">
            <img src={logo} alt="Greystone Logo" className="h-32 md:h-44" />
          </Link>
          <div className='ml-4 text-2xl text-primary-dark hidden md:flex md:items-center'>
              <Link to="/about" className="p-3 hover:text-primary-light" href="#">About</Link>
              <Link to="/products" className="p-3 hover:text-primary-light">Products</Link>
              <Link to="/pricing" className='p-3 hover:text-primary-light'>Materials Pricing</Link>
              <Link to="/contact" className="p-3 hover:text-primary-light">Contact Us</Link>
          </div>
          <div ref={menuRef} className={`absolute top-20 z-40 left-0 flex flex-col bg-primary-dark w-full text-2xl text-primary transition-all duration-300 ease-in-out ${isOpen ? 'flex flex-col max-h-72 opacity-100 border-t-2 border-primary-dark border-dotted' : 'hidden max-h-0 opacity-0'}`}>
              <Link to="/about" className='p-3 hover:bg-primary hover:text-primary-light'>About</Link>
              <Link to="/products" className="p-3 hover:bg-primary hover:text-primary-light">Products</Link>
              <Link to="/pricing" className="p-3 hover:bg-primary hover:text-primary-light">Materials Pricing</Link>
              <Link to="/contact" className="p-3 hover:bg-primary hover:text-primary-light">Contact Us</Link>
          </div>
      </nav>
    </div>
  );
}

export default NavBar;