import React from "react";
import gravel from '../assets/images/gravel.jpg';

interface PageHeaderProps {
    PageTitle: string;
    PageDescription?: string;
}

const PageHeader: React.FC<PageHeaderProps> = ({ PageTitle, PageDescription = null}) => {
    return (
        <div style={{ backgroundImage: `url(${gravel})` }}>
            <div className='bg-primary-dark bg-opacity-90 text-primary-light mt-20 w-screen'> 
                <div className="ml-4 mr-4 pt-16 pb-16 md:ml-32">
                    <h1 className="text-4xl md:text-6xl">{PageTitle}</h1>
                    { PageDescription !== null ? 
                        (
                            <p className="text-lg md:text-2xl pt-8 md:pr-24 xl:w-1/2">
                                {PageDescription}
                            </p>
                        )
                        : null 
                    }
                </div>
            </div>
        </div>
    );
}

export default PageHeader;