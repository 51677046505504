import React from "react";

interface GSInputProps {
    value: string;
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

const GSInput: React.FC<GSInputProps> = ({ value, onChange }) => {
  return (
    <input 
        type={value} 
        onChange={onChange}
        className="w-full p-2 mb-4 bg-primary-light bg-opacity-50 border-b-2 border-primary-dark rounded-sm focus:outline-none focus:ring-0 focus:border-b-2 focus:border-primary transition-all ease-in-out duration-300" 
    />    
  );
}


export default GSInput;