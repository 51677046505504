import React from "react";

interface InverseActionButtonProps {
  text: string;
  onClick?: () => void;
}

const InverseActionButton: React.FC<InverseActionButtonProps> = ({ text, onClick }) => {
  return (
    <button 
      className="border-2 border-primary-dark text-primary-light text-2xl p-2 rounded-sm bg-primary-dark hover:scale-105 transition-transform duration-300"
      onClick={onClick}
    >
      {text}
    </button>
  );
}

export default InverseActionButton;