import React from "react";

interface GSTextBoxProps {
    value: string;
    onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
}

const GSTextBox: React.FC<GSTextBoxProps> = ({ onChange }) => {
  return (
    <textarea 
        onChange={onChange}
        className="w-full p-2 mb-4 bg-primary-light bg-opacity-50 border-b-2 border-primary-dark rounded-sm focus:outline-none focus:ring-0 focus:border-b-2 focus:border-primary transition-all ease-in-out duration-300" 
        rows={5}
    />    
  );
}


export default GSTextBox;