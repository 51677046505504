import { createFileRoute } from '@tanstack/react-router'
import React, { useState } from 'react'
import NavBar from '../components/NavBar'
import hero from '../assets/images/hero.jpg';
import pillars from '../assets/images/pillars.jpg';
import ActionButton from '../components/ActionButton';
import GSInput from '../components/GSInput';

import { FaTruck, FaStoreAlt, FaCheck } from "react-icons/fa";
import { MdAttachMoney } from "react-icons/md";
import { IoPerson } from "react-icons/io5";
import InverseActionButton from '../components/InverseActionButton';
import Footer from '../components/Footer';
import { 
    useQuery, 
    useMutation, 
    useQueryClient
} from '@tanstack/react-query'

interface ContentfulProduct {
    productName: string;
    productPrice: number;
}

export const Route = createFileRoute('/pricing')({
  component: PricingCalculator,
})

function PricingCalculator() {
    const [width, setWidth] = useState(0);
    const [length, setLength] = useState(0);
    const [depth, setDepth] = useState(0);
    const [product, setProduct] = useState('');
    const [productCost, setProductCost] = useState(0);
    const [totalCubicYards, setTotalCubicYards] = useState(0);
    const [totalCost, setTotalCost] = useState(0);
    const [isValidWidthInput, setIsValidWidthInput] = useState(true);
    const [isValidLengthInput, setIsValidLengthInput] = useState(true);
    const [isValidDepthInput, setIsValidDepthInput] = useState(true);
    const [isShowResults, setIsShowResults] = useState(false);

    const { isPending, error, data, isFetching } = useQuery({
        queryKey: ['calculatorProducts'],
        queryFn: async () => {
            const response = await fetch('https://cdn.contentful.com/spaces/o3gxxglhw98e/environments/master/entries?access_token=' + process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN + '&content_type=calculatorProducts&include=2')
            return await response.json()
        }
    })

    if (isPending) return 'Loading...'

    if (error) return 'An error has occurred: ' + error.message

    const products: ContentfulProduct[] = data.items != null ?
        data.items.map((item: any) => {
            return {
                productName: item.fields.productName,
                productPrice: item.fields.productPrice
            }
        }) : null;    

    const productCosts: { [key: string]: number } = {
        "Cobblestone": 140,
        "Crushed Decorative Rock": 160,
        "Decorative Flag Stone": 200,
        "Landscape Boulders": 66.67,
        "Perma Bark": 200,
        "Roadbase": 40,
        "Diatomaceous Earth": 200,
        "Sand": 120
    };

    const handleWidthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const widthInYards = parseInt(e.target.value, 10) / 3;
        setWidth(widthInYards);
        setIsValidWidthInput(widthInYards > 0);
    };
    
      const handleLengthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const lengthInYards = parseInt(e.target.value, 10) / 3;
        setLength(lengthInYards);
        setIsValidLengthInput(lengthInYards > 0);
      };
    
    const handleDepthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const depthInYards = parseInt(e.target.value, 10) / 36;
        setDepth(depthInYards);
        setIsValidDepthInput(depthInYards > 0);
    };

    const handleProductChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedProduct = e.target.value;
        setProduct(selectedProduct); 
        setProductCost(productCosts[selectedProduct] || 0);
    };

    const handleSelectProductClick = () => {        
        if (width > 0 && length > 0 && depth > 0) {
            const cubicYards = length * width * depth;
            setTotalCubicYards(cubicYards);
            setTotalCost(cubicYards * productCost);
            setIsShowResults(true);
        }
        else {
            setIsValidWidthInput(width > 0);
            setIsValidLengthInput(length > 0);
            setIsValidDepthInput(depth > 0);
        }

        console.log(product);
    }
    
    return (
        <div className='h-screen bg-primary-light font-leagueSpartan'>
            <NavBar />
            <div className='bg-primary-dark text-primary-light mt-20 w-screen'> 
                <div className="ml-4 mr-4 pb-12 md:ml-32">
                    <h1 className="text-4xl pt-12 md:text-6xl">Stress Free Pricing <br /> Estimate</h1>
                    <p className="text-2xl pt-8 md:w-1/2">Getting an estimate is as easy as selecting a product and entering in the size of you’re space! </p>
                </div>
            </div>
            {
                !isShowResults ? (
                    <div className='relative bg-primary-light flex flex-col items-center justify-center md:pb-36'>            
                        <div className="flex flex-col bg-white text-center z-40 pt-12 pb-12 p-6 md:mt-24 md:mb-24 md:w-1/2 md:rounded-sm md:p-12">
                            <h2 className='text-5xl font-bold text-primary-dark pb-12'>Materials Calculator</h2>
                            <div className="flex flex-col pb-12">
                                <label className='text-left text-2xl font-bold text-primary-dark'>Width (Feet)</label>
                                <GSInput value="number" onChange={handleWidthChange} />
                                {!isValidWidthInput && <p className='text-red-500 text-sm text-left'>Value must be greater than 0</p>}
                            </div>
                            <div className="flex flex-col pb-12">
                                <label className='text-left text-2xl font-bold text-primary-dark'>Length (feet)</label>
                                <GSInput value="number" onChange={handleLengthChange} />
                                {!isValidLengthInput && <p className='text-red-500 text-sm text-left'>Value must be greater than 0</p>}
                            </div>
                            <div className="flex flex-col pb-12">
                                <label className='text-left text-2xl font-bold text-primary-dark'>Depth (inches)</label>
                                <GSInput value="number" onChange={handleDepthChange} />
                                {!isValidDepthInput && <p className='text-red-500 text-sm text-left'>Value must be greater than 0</p>}
                            </div>
                            <div className="flex flex-col pb-12">
                                <label className='text-left text-2xl font-bold text-primary-dark'>Product</label>
                                <select 
                                    id="products" 
                                    name="products" 
                                    onChange={handleProductChange} 
                                    className="w-full p-2 mb-4 bg-primary-light bg-opacity-50 border-b-2 border-primary-dark rounded-sm focus:outline-none focus:ring-0 focus:border-b-2 focus:border-primary transition-all ease-in-out duration-300">
                                        <option value="default">Select a Product</option>
                                        {products != null ?
                                            products.map((product: ContentfulProduct, index) => {
                                                return (
                                                    <option value={product.productName}>{product.productName}</option>
                                                );
                                            }) : null
                                        }
                                </select>
                            </div>
                            <ActionButton text="Calculate" onClick={handleSelectProductClick} />
                        </div>                
                    </div>       
                ) : (
                    <div className='relative bg-primary-light flex flex-col items-center justify-center md:pb-36'>            
                        <div className="flex flex-col bg-white text-center z-40 p-6 pt-12 pb-12 md:mt-24 md:mb-24 md:w-1/3 md:rounded-sm md:p-12">
                            <button onClick={() => setIsShowResults(false)} className='text-left p-4 text-xl text-primary hover:scale-105 transition-transform duration-300'>&larr; back</button>
                            <h2 className='text-5xl font-bold text-primary-dark pb-2 mb-12 border-b-2 border-primary border-opacity-40 rounded-sm'>Materials Calculator</h2>
                            <div className='flex flex-col pb-6 md:pl-8 md:pr-8 text-lg'>
                                <div className='flex items-center justify-between pb-4'>
                                    <p>Product</p>
                                    <p>{product}</p>
                                </div>
                                <div className='flex items-center justify-between pb-4'>
                                    <p>Cost/CY</p>
                                    <p>${productCost.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                                </div>
                                <div className='flex items-center justify-between pb-4'>
                                    <p>Total Cubic Yards</p>
                                    <p>{totalCubicYards.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                                </div>
                            </div>
                            <div className='border-b-2 border-primary border-opacity-40 mb-6'></div>
                            <div className='font-bold flex items-center justify-between md:pl-8 md:pr-8 pb-6'>
                                <p>Total Cost</p>
                                <p>${totalCost.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                            </div>
                            <ActionButton text="Order Now" />
                        </div>
                    </div>
                )
            }
            <div className='bg-primary w-full p-24 md:pl-36 md:pr-36 text-center flex flex-col items-center'>
                <h2 className='text-5xl pb-4 font-medium text-primary-dark'>Ask Us About Bulk Pricing</h2>
                <p className='pb-4 text-2xl text-primary-dark md:w-1/2'>
                    Need larger quantities of product? We offer bulk pricing on all of our products, give us a call to learn more.
                </p>
                <a href={`tel:385.400.4719`}>
                    <InverseActionButton text="Call Today!" />
                </a>
            </div>
            <div className='flex flex-col md:flex-row'>
                <div className='bg-primary-light text-primary-dark p-6 pt-20 pb-20 md:w-1/2 md:p-36'>
                    <h2 className='text-5xl pb-4 font-medium md:pb-12'>Materials Calculator</h2>
                    <p className='text-2xl'>Try our landscaping materials calculator to get an estimate on how much material 
                        you will need for your project and a cost estimate free of charge.
                    </p>
                    <p className='text-2xl pt-4'> 
                        Whether you're looking to find a price or need to figure out how much material you need, our calculator provides 
                        accurate and quick results. Just enter in the dimensions of the space you need to fill and select from one 
                        of our products to get started.
                    </p>
                </div>
                <img src={hero} alt="Hero" className='object-cover md:w-1/2'/>
            </div>
            <div className='flex flex-col md:flex-row'>
                <img src={pillars} alt="Pillars" className='object-cover order-last md:order-first md:w-1/2'/>
                <div className='bg-primary-light p-6 pt-20 pb-20 order-first md:order-last md:w-1/2 md:p-36'>
                    <h2 className='text-5xl pb-4 font-medium text-primary-dark md:pb-12'>Best Service Around</h2>
                    <p className='text-2xl'>
                    Our knowledgeable and friendly team is dedicated to ensuring you have the best experience possible. 
                    We take the time to understand your needs and provide personalized recommendations, making your landscaping 
                    project a success.
                    </p>
                </div>
            </div>
            <Footer />
        </div>
    );
}