import React from "react";

interface ActionButtonProps {
  text: string;
  disabled?: boolean;
  onClick?: () => void;
}

const ActionButton: React.FC<ActionButtonProps> = ({ text, disabled, onClick }) => {
  return (
    <button 
      className={`border-2 border-primary text-primary text-2xl p-2 rounded-sm bg-transparent hover:bg-primary hover:text-white ${disabled ? 'bg-gray-400 text-gray-700 cursor-not-allowed' : ''}`}
      onClick={onClick}
      disabled={disabled}
      type="button"
    >
      {text}
    </button>
  );
}

export default ActionButton;