import { createFileRoute } from '@tanstack/react-router'
import HomePage from '../components/HomePage'
import { ContentfulReview, ContentfulProductHighlight } from '../types/contentfulTypes'
import { 
  useQuery, 
  useMutation, 
  useQueryClient
} from '@tanstack/react-query'

export const Route = createFileRoute('/')({
  component: Index,
})

const access_token = process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN;

function Index() {

  const { isPending, error, data, isFetching } = useQuery({
    queryKey: ['reviews'],
    queryFn: async () => {
        const response = await fetch('https://cdn.contentful.com/spaces/o3gxxglhw98e/environments/master/entries?access_token=' + access_token + '&content_type=reviews')
        return await response.json()
    }
  });
  
  const productHighlightsQuery = useQuery({
    queryKey: ['productHighlights'],
    queryFn: async () => {
        const response = await fetch('https://cdn.contentful.com/spaces/o3gxxglhw98e/environments/master/entries?access_token=' + access_token + '&content_type=productHighlights&include=2')
        return await response.json()
    }
  });

  if (isPending || productHighlightsQuery.isPending) return 'Loading...';

  if (error) return 'An error has occurred: ' + error.message;

  console.log(productHighlightsQuery.data);
  const fetchedReviews: ContentfulReview[] = data.items.map((item: any) => {
      return {
          review: item.fields.review,
          author: item.fields.author
      }
  });

  const fetchedProductHighlights: ContentfulProductHighlight[] = productHighlightsQuery.data.items.map((item: any) => {
      console.log("Asset: " + productHighlightsQuery.data.includes.Asset);
      return {
          productName: item.fields.productName,
          productImage: productHighlightsQuery.data.includes.Asset.find((asset: any) => asset.sys.id === item.fields.productImage.sys.id).fields.file.url
    }
  });

  return (
    <div className='h-screen bg-primary-light'>
      <HomePage reviews={fetchedReviews} productHighlights={fetchedProductHighlights}/>
    </div>
  )
}
