import { createFileRoute } from '@tanstack/react-router'
import NavBar from '../components/NavBar'
import ProductCard from '../components/ProductCard'

import { 
    useQuery, 
    useMutation, 
    useQueryClient
} from '@tanstack/react-query'
import Footer from '../components/Footer';
import InverseActionButton from '../components/InverseActionButton';
import ActionButton from '../components/ActionButton';
import PageHeader from '../components/PageHeader';

interface ContentfulProduct {
    productName: string;
    productDescription: string;
    productImage: string;
}

export const Route = createFileRoute('/products')({
  component: Products,
})

function Products() {
    const { isPending, error, data, isFetching } = useQuery({
        queryKey: ['products'],
        queryFn: async () => {
            const response = await fetch('https://cdn.contentful.com/spaces/o3gxxglhw98e/environments/master/entries?access_token=' + process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN + '&content_type=product&include=2')
            return await response.json()
        }
    })

    if (isPending) return 'Loading...'

    if (error) return 'An error has occurred: ' + error.message

    const products: ContentfulProduct[] = data.items != null ?
        data.items.map((item: any) => {
            const productImage = data.includes.Asset.find((asset: any) => asset.sys.id === item.fields.productImage.sys.id);
            return {
                productName: item.fields.productName,
                productDescription: item.fields.productDescription,
                productImage: data.includes.Asset.find((asset: any) => asset.sys.id === item.fields.productImage.sys.id).fields.file.url,
            }
        }) : null;    

    return (
        <div className='bg-primary-light'>
            <NavBar />
            <PageHeader PageTitle={"Landscape Materials"} PageDescription={"We are dedicated to helping you create stunning outdoor spaces that enhance the beauty and functionality of your property."}/>
            <div className='w-full flex flex-col justify-center items-center md:mt-16'>
                {products != null ? 
                    products.map((product: ContentfulProduct, index) => {
                        if (index === products.length - 1) {
                            return (
                                <ProductCard productName={product.productName} productDescription={product.productDescription} productImage={product.productImage} isLastProduct={true} index={index} />
                            );
                        }
                        else {
                            return (
                                <ProductCard productName={product.productName} productDescription={product.productDescription} productImage={product.productImage} isLastProduct={false} index={index}/>
                            );
                        }
                    }) : (
                        <div>No Products Found </div>
                    )
                }
            </div>
            <div className='relative bg-primary-light flex flex-col items-center justify-center md:mt-64'>
                <div className="md:absolute -top-72 bg-white text-center z-40 p-6 pt-12 pb-12 w-full md:mt-36 md:w-5/12 md:rounded-sm md:p-12">
                    <h2 className='text-4xl md:text-6xl pb-12 text-primary-dark'> Get your material ordered today.</h2>
                    <a href={`tel:385.400.4719`}>
                        <ActionButton text="Give Us a Call" />
                    </a>
                </div>
            </div>
            <div className="bg-primary-dark md:pt-96">
                <Footer />
            </div>
        </div>
    )
}