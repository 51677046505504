import { createFileRoute, Link } from '@tanstack/react-router'
import NavBar from '../components/NavBar'
import GSInput from '../components/GSInput'
import GSTextBox from '../components/GSTextBox'
import ActionButton from '../components/ActionButton'
import Footer from '../components/Footer'
import { useState } from 'react';
import emailjs from '@emailjs/browser';
import ReCAPTCHA from 'react-google-recaptcha';


export const Route = createFileRoute('/contact')({
    component: Contact,
  })

function Contact() {
    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [message, setMessage] = useState<string>('');
    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const [isVerified, setIsVerified] = useState(false);
    const [isSent, setIsSent] = useState(false);

    const handleRecaptchaChange = (value: string | null) => {
        setIsVerified(!!value);
    }

    const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFirstName(e.target.value);
    };

    const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLastName(e.target.value); 
    };

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
    };

    const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPhoneNumber(e.target.value);
    };

    const handleMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setMessage(e.target.value);
    };

    const handleFormSubmit = () => {     
        var templateParams = {
            from_name: `${firstName} ${lastName}`,
            email: email,
            phone: phoneNumber,
            message: message
        };

        emailjs.send('service_y44kas8', 'template_hszf0pu', templateParams, {
            publicKey: process.env.REACT_APP_EMAILJS_PUBLIC_KEY,
            limitRate: {
                id: 'greystone',
                // Allow 1 request per 10s
                throttle: 10000,
              }
        }).then(
            (response) => {
                console.log('SUCCESS!', response.status, response.text);
                setIsSent(true);
            },
            (error) => {
              console.log('FAILED...', error);
            });
    };

    return (
        <div className='bg-primary-light'>
            <NavBar /> 
            <div className='bg-primary-dark text-primary-light mt-20 w-screen'> 
                <div className="ml-4 mr-4 pb-12 md:ml-32">
                    <h1 className="text-4xl pt-12 md:text-6xl">Contact Us</h1>
                </div>
            </div>       
            <h2 className='md:ml-64 md:mr-64 ml-6 mr-6 mt-20 text-primary-dark text-6xl font-bold'>Best Service Around!</h2>
            <p className='text-xl p-6 md:ml-64 md:mr-64'>
                At GreyStone, we pride ourselves on exceptional customer service and expertise. Our knowledgeable team is here to guide you in 
                selecting the right materials for your project, ensuring that you achieve the look and functionality you desire.
            </p>
            <p className='text-xl p-6 pt-0 md:ml-64 md:mr-64'>
                We are dedicated to helping you create stunning outdoor spaces that enhance the beauty and functionality of your property.
                If you have any questions or would like to place an order, please don't hesitate to reach out to us. We are here to help you!
            </p>
            {!isSent ? (
                <form className='flex justify-center mb-40'>
                    <div className='bg-white text-primary-dark rounded-md mt-20 w-full md:w-2/3'>
                        <h2 className='text-6xl text-center font-bold p-6'>Contact Us</h2>
                        <div className='flex flex-col justify-center w-full md:pl-12 md:pr-12 mb-12'>
                            <div id="Name" className='flex flex-col md:flex-row justify-between pl-6 pr-6 md:p-2'>
                                <div className='md:w-1/2 md:mr-4'>
                                    <label className='text-left text-2xl font-bold'>First Name</label>
                                    <GSInput value="string" onChange={handleFirstNameChange} />
                                </div>
                                <div className='md:w-1/2 md:ml-4'>
                                    <label className='text-left text-2xl font-bold'>Last Name</label>
                                    <GSInput value="string" onChange={handleLastNameChange}/>
                                </div>
                            </div>
                            <div className="flex flex-col md:flex-row justify-between pl-6 pr-6 md:p-2">
                                <div id="email" className="md:w-1/2 md:mr-4">
                                    <label className='text-left text-2xl font-bold'>Email</label>
                                    <GSInput value="string" onChange={handleEmailChange}/>
                                </div>
                                <div id="phonenumber" className="md:w-1/2 md:ml-4">
                                    <label className='text-left text-2xl font-bold'>Phone Number</label>
                                    <GSInput value="number" onChange={handlePhoneNumberChange}/>
                                </div>
                            </div>
                            <div className='pl-6 pr-6 md:p-2'>
                                <label className='text-left text-2xl font-bold'>Message</label>
                                <GSTextBox value="string" onChange={handleMessageChange}/>
                            </div>
                            <div className='flex justify-center pb-6'>
                                <ReCAPTCHA
                                    sitekey={process.env.REACT_APP_RECAPTCHA_KEY || 'site_key'}
                                    onChange={handleRecaptchaChange}
                                />
                            </div>
                            <ActionButton text="Submit" disabled={!isVerified} onClick={handleFormSubmit}/>
                        </div>
                    </div>                
                </form>  
            ) : 
                <div className='flex justify-center pb-24'>
                    <div className='bg-white text-primary-dark rounded-md mt-20 w-full md:w-2/3'>
                        <h2 className='text-6xl text-center font-bold p-6'>Thank you for reaching out!</h2>
                        <p className='text-xl p-6'>
                            We have received your message and will get back to you as soon as possible. 
                            If you need immediate assistance, please call us at 385.400.4719. 
                        </p>
                    </div>
                </div>
            }         
            <div className='flex flex-col md:flex-row justify-center border-b-2 border-primary'>
                <div className='md:w-1/2 p-12 bg-primary-dark text-primary-light'>
                    <h2 className='pb-12 text-6xl font-bold'>Come See Us!</h2>  
                    <p className='text-xl'>
                        Looking to pick up top-quality landscaping materials or interested in seeing our 
                        products firsthand? Our knowledgeable team is here to assist you with all your landscaping 
                        needs, from selecting the ideal materials for your project to answering any questions you 
                        may have.                      
                    </p>
                    <p className='text-xl pt-12'>
                        We’re open Monday through Friday, 9:00 A.M. to 5:00 P.M., and Saturday from 8:00 
                        A.M. to 12:00 P.M. Stop by today and see why we're the trusted source for landscaping 
                        materials in the area!                                                           
                    </p>
                </div>
                <div className='md:w-1/2 p-6 md:p-12 bg-primary-dark'>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3001.3339845603236!2d-112.025922!3d41.214491699999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87530e37b2ecf70d%3A0x8d994e4d09b7add2!2s2797%20S%201900%20W%2C%20West%20Haven%2C%20UT%2084401!5e0!3m2!1sen!2sus!4v1730953504024!5m2!1sen!2sus" className='w-full h-56 md:h-[450px]' style={{ border: 0 }} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
            <Footer />
        </div>
    )
}