import React, { useEffect, useState } from "react";
import { 
    useQuery, 
    useMutation, 
    useQueryClient
} from '@tanstack/react-query'
import { ContentfulReview } from '../types/contentfulTypes'


interface ReviewCarouselProps {
    reviews: ContentfulReview[];
}

const ReviewCarousel: React.FC<ReviewCarouselProps> = ({ reviews }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const nextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % reviews.length);
    };

    const prevSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + reviews.length) % reviews.length);
    };
    
    const goToSlide = (index: number) => {
        setCurrentIndex(index);
    };


    return (
        <div className="relative w-full mx-auto bg-white rounded-sm text-center p-6 pt-24 pb-24">
            <h2 className='text-4xl md:text-6xl pb-4 text-primary-dark'> Reviews</h2>
            <div id="reviews" className="overflow-hidden relative text-primary-dark">
                <div className="flex transition-transform duration-500" style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
                    {reviews.map((review, index) => (
                        <div key={index} className="min-w-full pb-12 flex flex-col items-center justify-center">
                            <div className="w-full text-lg">
                                <p className="p-2 text-2xl">{review.review}</p>
                                <p className="italic text-xl">-{review.author}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div>
                {reviews.map((_, index) => (
                    <button onClick={() => goToSlide(index)} className={`w-4 h-4 m-0.5 rounded-full hover:bg-primary ${currentIndex === index ? 'bg-primary' : 'bg-gray-400 bg-opacity-20'}`}>
                        <span className="sr-only">Slide {index}</span>
                    </button>
                ))}
            </div>
        </div>
    );
}

export default ReviewCarousel;